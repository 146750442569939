<template>
  <div class="app">
    <toubu></toubu>
    <div style="clear: both;" class="">
      <BDhear :num="1"></BDhear>
    </div>
    <!-- <div class="app-h">选择标段</div> -->
    <div class="app-xt">
      <p
        style="
          color: red;
          font-weight: 600;
          padding-top: 18px;
          font-size: 18px;
          text-align: center;
        "
      >
        <span style="font-size: 18px;">注意</span>: 请使用360浏览器兼容模式访问
      </p>
      <div class="c-div">
        <div
          style="position: relative;"
          :class="cutIndex == index ? 'app-cs zi appcs' : 'app-c zi appcs'"
          @click="handleMap(index, item)"
          v-for="(item, index) in arr"
          :key="index"
        >
          <!-- <div class="c-div"> -->
          <img class="c-img" :src="item.orgLogo" alt="" />
          <div class="c-txt zitxt">
            <div class="txt-1">{{ item.orgName }}</div>
            <div class="txt-2">类型：担保公司</div>
            <div class="txt-3">
              费率：{{ countDecimalPlaces(item.orgRate) }}
            </div>
            <!-- <div
              class="cenad"
              v-if="item.guarantee_company_name == '河南省中豫工程担保有限公司'"
            >
              线上测试，请勿选择
            </div> -->
            <!-- <div class="txt-4">{{ item.guarantee_company_desc }}</div> -->
          </div>
          <el-button
            style="margin-top: 0.75rem; width: 33%;"
            type="primary"
            plain
            >选择</el-button
          >
        </div>

        <div class="qingchu"></div>
      </div>
    </div>
    <div class="waocless qingchu where" style="">
      <div style="" class="homexin5">
        <el-pagination
          class="ieright"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="8"
          layout="total, sizes, prev, pager, next, jumper"
          :total="zongshu"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="publicDialog"
      :showClose="false"
      :closeOnClickModal="false"
      :visible.sync="ssdialogVisible"
      width="40%"
    >
      <div slot="title">
        <i
          style="color: #e6a23c; font-size: 19px;"
          class="el-icon-message-solid"
        ></i>
        <span>警告信息</span>
      </div>
      <div>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <div class="fontpx">当前信息：</div>
              <div style="border-right: 0px solid #7f7f7f;" class="gridur">
                <div>{{ xuanze.orgName }}</div>
                <div>当前担保公司</div>
                <div>{{ xuanze.unifyCode }}</div>
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <div class="fontpx">最后一次信息：</div>
              <div class="gridur">
                <div>{{ baohanxq.guaranteeCompanyName }}</div>
                <div>上次担保公司</div>
                <div>{{ baohanxq.guaranteeUnifyCode }}</div>
              </div>
            </div></el-col
          >
        </el-row>
        <!-- style="text-align: center !important;" -->

        <div class="fontpx">
          两次信息不一致，如果需要变更请点击变更，不需要变更请点击下一步
        </div>
      </div>
      <span slot="footer" class="dialog-footer footer">
        <div class="foossster">
          <el-button @click="project">下一步</el-button>
          <el-button @click="ssdialogVisible = false">变更</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- <div class="butt">
      <div @click="project" class="button-i">下一步</div>
    </div> -->
    <dinad class="qingchu"></dinad>
  </div>
</template>

<script>
import dinad from '../../components/dinad.vue'
import toubu from '../../components/toubu.vue'
import $ from 'jquery'
import { orginlist, applyprogress, companyst, bhprocess } from '@/api/user'

import axios from 'axios'
import BDhear from '../../components/BDhear.vue'
export default {
  components: {
    dinad,
    BDhear,
    toubu
  },
  data() {
    return {
      tabPosition: 'right',
      ssdialogVisible: false,
      tab: 'left',
      arr: [
        // {
        //   guarantee_comid: 1,
        //   guarantee_company_desc: "公司介绍",
        //   guarantee_company_logo:
        //     "https://www.hnzyxsbh.com/uploads/20221101/4c86d6af54545ba5bede449fce633e6a.png",
        //   guarantee_company_name: "河南中唐工程担保有限公司",
        //   guarantee_company_rate: "0.0030",
        //   guarantee_company_type: "担保公司",
        //   unify_code: "91411082MA9KKXJ60L",
        // },
      ],
      zongarr: [],
      zongshu: 0,
      cutIndex: -1,
      radio1: '1',
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 1,
      code: '',
      cion: {
        applyno: '',
        unify_code: ''
      },
      biddercode: '',
      biddername: '',
      contractId: '',
      bzjamount: '',
      baohanno: '',
      tijiao: {
        applyno: '',
        type: 1
      },
      tang: false,
      zhang: false,
      imgName: '../../assets/login/tang1.png',
      xuanze: {},
      baohanxq: {
        guaranteeCompanyName: null
      },
      xieyi: null,
      gongsi: {
        ztype: 0,
        pageNum: 1,
        pageSize: 8
      }
    }
  },
  async created() {
    this.bbv() //获取担保公司列表
    // console.log("123123123",this.$route.query.code==undefined);
    if (this.$route.query.code == undefined) {
      return
    } else {
      // 存储applyno
      let a = this.$route.query.code
      let b = ''
      if (a.includes('%')) {
        console.log('包含%')
        b = a.split('%')[0]
      } else {
        console.log('不包含%', b)
        b = a
      }

      console.log('b', b)
      // sessionStorage.setItem('applyno', this.$route.query.code) //线上
      sessionStorage.setItem('applyno', b) //线上
      // sessionStorage.setItem('applyno', 'TBaoHanHNYZTBBH2024060314033006554') //测试
      sessionStorage.setItem('qicode', '不显示')
      console.log('code90909090')
      // this.cion.applyno = this.$route.query.code //线上
      this.cion.applyno = b //线上
      // this.cion.applyno = 'TBaoHanHNYZTBBH2024060314033006554' //线下
      const { data } = await applyprogress(this.cion.applyno) //获取保函详情
      console.log('保函详情', data.data)
      this.baohanxq = data.data.bahan
      sessionStorage.setItem('biddercode', this.baohanxq.biddercode)
      sessionStorage.setItem('baohanno', this.baohanxq.baohanno)
    }
  },
  methods: {
    countDecimalPlaces(num) {
      // num = num * 1
      // var ci = null
      // var temp = num.toString()

      // if (/\d+(\.\d+)?/.test(temp)) {
      //   ci = parseInt(temp[temp.length - 1])
      // }

      // const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
      // if (!match) {
      //   return 0
      // }
      // // 判断科学计数法中的小数位数
      // const decimalPlacesInScientificNotation = Math.max(
      //   0,
      //   (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
      // )
      // // 判断普通小数中的小数位数
      // const decimalPlacesInNormalNotation = match[0].includes('.')
      //   ? match[0].split('.')[1].length
      //   : 0

      var cads = num * 1000 + '‰'
      return cads
      // if (
      //   Math.max(
      //     decimalPlacesInNormalNotation,
      //     decimalPlacesInScientificNotation
      //   ) == 3
      // ) {
      //   cads = ci + '‰'
      // } else {
      //   // cads = "2.5‰";
      // }
      // if (
      //   Math.max(
      //     decimalPlacesInNormalNotation,
      //     decimalPlacesInScientificNotation
      //   ) == 4
      // ) {
      //   cads = ci + '%'
      // }
      // 返回小数位数
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      // this.array.slice(val,6)
      this.gongsi.pageSize = val
      this.bbv()
      // this.arr = this.zongarr.slice(0, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.gongsi.pageNum = val
      this.bbv()
    },
    dateFormat(value) {
      let date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s //多种时间格式的拼接
    },

    async handleMap(state, i) {
      // this.ssdialogVisible = true;
      this.cutIndex = state
      // console.log(state, i,this.baohanxq);
      this.xuanze = i
      this.cion.unify_code = i.unifyCode
      // 判断当前保函是否有选择担保公司
      if (this.baohanxq.guaranteeUnifyCode == null) return this.project()
      // 判断当前保函担保公司是否是上次选择的担保公司
      if (this.baohanxq.guaranteeUnifyCode == i.unifyCode) return this.project()

      this.ssdialogVisible = true
    },
    async chaxun() {
      // var bbc = sessionStorage.getItem("applyno");
      // const { data } = await bhprocess(bbc);  //查询保函流程状态
      var that = this
      // console.log("首页底部打印", data,data.data);

      this.xieyi = this.xuanze.status
      sessionStorage.setItem('xieyi', this.xieyi)
      // console.log("chaxun()sessionStorage.getItem",this.xuanze.unify_code=='91410296MA46T65N4E',data.data.xieyi)
      // return
      if (false) {
        that.xuanze.unifyCode == '91410296MA46T65N4E'
        that.$router.push({
          // name: "project",
          name: 'shouxin',
          params: {}
        })
        sessionStorage.setItem('xieyi', 0)
      } else {
        if (that.xieyi == 1) {
          return that.$router.push({
            // name: "project",
            name: 'project',
            params: {}
          })
        } else {
          that.$router.push({
            // name: "project",
            name: 'shouxin',
            params: {}
          })
        }
      }
      //  this.xieyi=0
    },
    async bbca() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      var bbc = {
        applyno: this.cion.applyno,
        unisyCode: this.cion.unify_code
      }

      const { data } = await companyst(bbc)
      loading.close()
      if (data.code == 200) {
        this.$message({
          showClose: true,
          message: data.msg,
          type: 'success'
        })
        sessionStorage.setItem('baohanno', data.data.baohanno)
        this.chaxun()
      } else {
        this.$message({
          showClose: true,
          message: data.msg,
          type: 'error'
        })
      }
    },
    async project() {
      var bbc = this.cion.unify_code
      sessionStorage.setItem('unify_code', bbc)
      var bbcc = this.cion.applyno
      if (bbcc == '') {
        return this.$message({
          showClose: true,
          message: '请输入保函编号',
          type: 'error'
        })
      } else {
        this.bbca()
      }
    },
    async bbv() {
      var that = this
      //  orginlist(this.gongsi).then(response => {
      //   console.log("处理响应数据",response);
      //     // 处理响应数据
      //   })
      //   .catch(error => {
      //     console.log("处理错误",error);
      //     // 处理错误
      //   });
      //   return
      const { data } = await orginlist(this.gongsi)
      // alert('担保公司列表',JSON.stringify(data.rows));
      var array = data.rows
      console.log('array', array)
      for (let index = 0; index < array.length; index++) {
        array[index].orgLogo = 'http://www.hnzyxsbh.com' + array[index].orgLogo
      }
      that.zongshu = data.total
      that.zongarr = array.reverse()
      that.arr = array.slice(0, 8)
      console.log(that.arr, 'that.arr')
    }
  }
}
</script>
<style>
.gridur {
  padding: 5px;
  border: 1px solid #7f7f7f;
  height: 60px;
}
.fontpx {
  font-size: 16px;
  margin: 10px 0;
}
.grid-content {
  font-size: 16px;
}
.el-message-box .el-message-box__btns {
  text-align: center !important;
}
.el-dialog .el-dialog__body {
  padding: 0 1.25rem !important;
}
/* .el-dialog  .el-dialog__header{
  display: none;
} */
.foossster {
  text-align: center !important;
}
</style>
<style scoped>
html {
  width: 100vw;
  width: 100%;
  background: #eeee !important;
}
.app {
  height: 100%;
  /* display: flex;
  flex-direction: column; */
}
.asdding {
  position: fixed;
  right: 20px;
  bottom: 30%;
}
.homexin1 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homexin5 {
  width: 100%;
  margin-top: 2rem;
  height: 4rem;
  display: flex;
  padding-right: 10px;
  box-sizing: border-box;
  justify-content: flex-end;
}
.asdzong {
  position: relative;
  width: 200px;
  background: #ffffff;
  display: flex;
  border: 1px #c5c5c5 solid;
  border-top: 3px tomato solid;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 20px;
}
.asdimgh {
  width: 60px;
  height: 60px;
}
.block {
  position: absolute;
  right: 100%;
}
.app {
  /* background: #fff; */
  /* padding: 0 20%; */
}
.app-xt {
  /* z-index: 9999; */
  /* padding: 0 8%; */
  background: #fff;
  flex: 1;
}
.app-h {
  padding-left: 1.6875rem;
  /* width: 100%; */
  height: 2.6875rem;
  line-height: 2.6875rem;
  background: #1890ff;
  font-size: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.erqiwu {
  height: 2.75rem;
  background: #069cff;
  border-radius: 0px 0px 0px 0px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-a {
  display: flex;
  /* margin-top: 2.75rem; */
  position: relative;
}
.a-left {
  width: 50%;
  height: 3.0625rem;
  display: flex;
  line-height: 3.125rem;
  align-content: center;
  justify-content: center;
  background: #069cff;
  border: 0.0625rem solid #f0f3fb;
  border-right: 0;
  margin-top: 1px;
  border: 1px #069cff solid;
  /* border-radius: 0.5625rem 0rem 0rem 0.5625rem; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 440;
  color: #ffffff;
}
.rihasd {
  color: #069cff;
}
.waocless {
  /* padding: 0 8%;  */
  /* padding-right: 15px; */
  padding-top: 148px;
  background: #fff;
}
.a-img {
  padding-top: 0.85rem;
  padding-right: 0.9375rem;
  width: 1.25rem;
  height: 1.3rem;
}
.aa-img {
  position: absolute;
  height: 48.96px;
  margin-top: 0.0625rem;
  padding: 0;
  left: 45%;
}
.a-right {
  margin-top: 1px;
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 3.125rem;
  width: 50%;
  height: 3.0625rem;
  /* background: #cdebff; */
  border: 1px #069cff solid;
  /* border-radius: 0.4375rem; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  margin-top: 3.8125rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.app-c {
  /* width: 44.9%; */
  /* flex: 1; */
  margin-right: 1%;
  margin-left: 2.2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 304px;
  background: #ffffff;
  margin-top: 1.875rem;
  /* padding: 1.25rem; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(21% - 40px);
  padding: 20px;

  /* flex: 1; */
  margin-top: 1.075rem;
  /* padding: 1.25rem; */
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 0.0625rem solid #efefef;
  /* padding: 0 10px; */
}
.app-cs {
  margin-right: 1%;
  margin-left: 2.2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 354px;
  background: #ffffff;
  margin-top: 1.875rem;
  /* padding: 1.25rem; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(21% - 40px);
  padding: 20px;
  /* padding: 0 10px; */
  /* flex: 1; */
  margin-top: 1.875rem;
  /* padding: 1.25rem; */
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 0.0625rem solid #efefef;
  border: 1px solid #069cff;
  -webkit-box-shadow: 0px 0px 6px 0px rgb(6 156 255 / 40%);
  box-shadow: 0px 0px 6px 0px rgb(6 156 255 / 40%);
}
.app-b2 {
  display: flex;
  line-height: 1.5rem;
  font-size: 1rem;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 1.625rem;
}
.b-txt {
  width: 18.5rem;
  height: 1.7rem;
  flex: 1;
  background: #f3f3f3;
  border: 0.0625rem solid #dedede;
  padding-left: 0.375rem;
  line-height: 1.7rem;
  font-size: 14.5px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 9.0625rem;
  height: 9.0625rem;
}
.c-txt {
  /* width: 56%; */
  /* padding: 0.525rem 0rem 1rem 1.5rem; */
}
.txt-1 {
  font-size: 1.0525rem;
  font-family: Microsoft YaHei;
  font-weight: 550;
  color: #333333;
  /* text-align: center; */
  margin-top: 0.75rem;
}
.txt-2 {
  margin-top: 0.9625rem;
  font-size: 0.9rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 0.75rem;
  font-size: 0.9rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 0.9375rem;
  font-size: 0.9rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  /* margin-top: 1.25rem; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  background: #fff;
}
.money {
  align-items: center;
  margin-top: 28px;
  margin-left: 60px;
}
.cenad {
  text-align: center;
  background: #1795ff;
  color: #fff;
  border-radius: 1px;
}
.cioasdasd {
  margin-top: 8px !important;
}
.font_style {
  font-size: 17px;
  color: #333333;
  font-size: Regular;
  /* margin-top: 28px; */
  margin-left: 60px;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-bottom: 3rem;

  width: 20%;
  /* position: fixed; */
  bottom: 20%;
  /* height: 5.5%; */
  padding: 0.3125rem 0;
  line-height: 1.875rem;
  border-radius: 0.625rem;
  background: #069cff;
  text-align: center;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
.order_details_box {
  width: 100%;
  height: 200px;
  /* margin-top: 5%; */
  background-color: #f5f6fa;
  display: flex;
  height: 150px;
  /* position: relative;
  left: 15%;
  top: 5%; */
}
</style>
